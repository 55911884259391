<template>
  <GChart
    v-if="chartData.length > 1"
    type="LineChart"
    :data="chartData"
    :options="chartOptions"
    style="width: 500px; height: 250px;"
  />
</template>

<script>
import moment from 'moment'
import { GChart } from 'vue-google-charts/legacy'

export default {
  name: 'BarChart',
  components: { GChart },
  data () {
    return {
      // chartData: [
      //   ['Month', (new Date().getFullYear() - 1).toString(), (new Date().getFullYear()).toString()],
      //   ['2014', 3000, 400],
      //   ['2015', 1170, 460],
      //   ['2016', 660, 1120],
      //   ['2017', 1030, 540],
      // ],
      dMobile: undefined,
      chartOptions: {
        chart: {
          title: 'Q' + moment().quarter() + ' ' + new Date().getFullYear() + 'Charter Flight Forecast',
          subtitle: '',
        },
      },
    }
  },
  mounted () {
    this.dMobile = document.querySelector('html').classList.contains('d-mobile');
  },
  computed: {
    chartData () {
      const getData = ({name = '', col1 = '', col2 = ''} = {}) => [(this.dMobile ? name.substring(0, 3) : name), parseInt(col2.replaceAll(',', '')), parseInt(col1.replaceAll(',', ''))]

      const months = this.$store.state.argusStatistic.secondTableData.slice(0, 3)

      return [
        ['Month', (new Date().getFullYear() - 1).toString(), (new Date().getFullYear()).toString()],
        ...months.map(getData),
      ]
    },
  },
  methods: {
    moment,
  },
}
</script>
