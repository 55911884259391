<template>
  <div class="box box--blog">
    <div class="box__inner box__inner--blog">
      <div class="blog-search">
        <BlogSubcategories/>
        <button class="blog-search__button" type="button" @click="$router.push('/theflyreport/search')">
          <div v-if="$store.state.view.theme === 'dark'">
            <SvgIcon name="magnifier-light"/>
          </div>
          <div v-if="$store.state.view.theme === 'light'">
            <SvgIcon name="magnifier-dark"/>
          </div>
        </button>
      </div>
      <div class="blog-interface">
        <vue-scroll :ops="ops">
          <div class="blog__monitor-container">
            <div class="blog__monitor-title">
              <h2>Argus Charter Activity Monitor</h2>
            </div>
            <div class="blog__monitor-table-wrapper">
                <h4 class="blog__monitor-table-title">Total Charter Flight Activity</h4>
                <div class="blogMonitor__table">
                  <div class="blogMonitor__table-header">
                    <div class="blogMonitor__table-cell"></div>
                    <div class="blogMonitor__table-cell">{{ new Date().getFullYear() }}</div>
                    <div class="blogMonitor__table-cell">{{ new Date().getFullYear() - 1 }}</div>
                    <div class="blogMonitor__table-cell">{{ new Date().getFullYear() - 4 }}</div>
                  </div>
                  <div class="blogMonitor__table-body">
                      <div class="blogMonitor__table-row" v-for="(item, index) of firstTableData" :key="index">
                        <div class="blogMonitor__table-cell">
                          <div class="blogMonitor__table-index">{{ item.name }}</div>
                        </div>
                        <div class="blogMonitor__table-cell">
                          <span>{{ item.col1 }}</span>
                        </div>
                        <div class="blogMonitor__table-cell">
                          <span>{{ item.col2 }}</span>
                        </div>
                        <div class="blogMonitor__table-cell">
                          <span>{{ item.col3 }}</span>
                        </div>
                      </div>
                  </div>
                </div>
                <div style="font-size: 12px; padding-top: 10px;">
                  <a href="https://www.argus.aero/aviation-analytics/traq-pax/" target="_blank">source: www.argustraqpack.com</a>
                </div>
              </div>
            <div class="blog__monitor-bottom">
              <div class="blog__monitor-table-wrapper">
                  <h4 class="blog__monitor-table-title" style="margin-top: 40px;">3-month charter forecast vs. previous</h4>
                  <div class="blogMonitor__table">
                    <div class="blogMonitor__table-header">
                      <div class="blogMonitor__table-cell"></div>
                      <div class="blogMonitor__table-cell">’{{new Date().getFullYear().toString().substr(-2)}} - ’{{(new Date().getFullYear() + 1).toString().substr(-2)}}</div>
                      <div class="blogMonitor__table-cell">’{{(new Date().getFullYear() - 1).toString().substr(-2)}} - ’{{new Date().getFullYear().toString().substr(-2)}}</div>
                      <div class="blogMonitor__table-cell" style="text-transform: capitalize">Diff</div>
                    </div>
                    <div class="blogMonitor__table-body">
                        <div class="blogMonitor__table-row quarter" v-for="(item, index) of secondTableData" :key="index">
                          <div class="blogMonitor__table-cell">
                            <div class="blogMonitor__table-index">{{ item.name }}</div>
                          </div>
                          <div class="blogMonitor__table-cell">
                            <span>{{ item.col1 }}</span>
                          </div>
                          <div class="blogMonitor__table-cell">
                            <span>{{ item.col2 }}</span>
                          </div>
                          <div class="blogMonitor__table-cell">
                            <span>{{ item.col3 }}</span>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
              <div class="blog__monitor-bottom-diagram">
                <BarChart/>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
import BlogSubcategories from '@/components/Blog/BlogSubcategories/BlogSubcategories';
import './Blog.styl';
import BarChart from '@/components/BarChart/BarChart';

export default {
  name: 'Article',
  data: () => ({
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: '#bdbaba',
      },
    },
    // firstTableData: [
    //   {
    //     name: 'Year-To-Date',
    //     col1: '1,034,332',
    //     col2: '1,064,897',
    //     col3: '867,349',
    //   },
    //   {
    //     name: 'Weekly (10/1 - 10/7)',
    //     col1: '26,534',
    //     col2: '24,952',
    //     col3: '22,379',
    //   },
    // ],
    // secondTableData: [
    //   {
    //     name: 'October',
    //     col1: '114,183',
    //     col2: '116,806',
    //     col3: '2000',
    //   },
    //   {
    //     name: 'November',
    //     col1: '114,214',
    //     col2: '117,145',
    //     col3: '2000',
    //   },
    //   {
    //     name: 'December',
    //     col1: '110,629',
    //     col2: '119,976',
    //     col3: '2000',
    //   },
    //   {
    //     name: 'Q4 TOTAL',
    //     col1: '342,026',
    //     col2: '353,927',
    //     col3: '2000',
    //   },
    // ],

  }),
  computed: {
    firstTableData () {
      return this.$store.state.argusStatistic.firstTableData;
    },
    secondTableData () {
      return this.$store.state.argusStatistic.secondTableData;
    },
  },
  components: {
    BarChart,
    SvgIcon,
    BlogSubcategories,
  },
  mounted () {
    this.$store.dispatch('getArgusStatistic');
  },
  metaInfo: {
    meta: [{
      name: 'robots', content: 'noindex',
    }],
  },
};
</script>
